import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const { devices, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledDivider = styled(forwardRef(({ desktop, mobile, size, ...props }, ref) => <div ref={ref} {...props} />))`
  height: ${spaces.regular}px;
  width: 100%;

  ${({ size }) =>
    size !== undefined &&
    css`
      height: ${size}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      height: 0;
    `}

  @media ${devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        height: 0;
      `}

    ${({ mobile, size }) =>
      mobile &&
      size !== undefined &&
      css`
        height: ${size}px;
      `}
  }
`;

const Divider = ({ size, desktop, mobile }) => (
  <StyledDivider size={size} desktop={desktop} mobile={mobile} />
);

Divider.propTypes = {
  size: PropTypes.number,
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default Divider;
