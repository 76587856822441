import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const { colors, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledLine = styled(forwardRef(({ desktop, mobile, color, size, ...props }, ref) => (<div ref={ref} {...props} />)))`
  height: 1px;
  margin-bottom: ${spaces.regular}px;
  background-color: ${colors.gray[200]};

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}

    ${({ mobile, size }) =>
      mobile &&
      size &&
      css`
        height: ${size}px;
      `}
  }
`;

const Line = ({ desktop, mobile, color, size }) => (
  <StyledLine desktop={desktop} mobile={mobile} color={color} size={size} />
);

Line.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Line;
