import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const { colors, devices, fontSizes, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledTitle = styled(forwardRef(({ desktop, mobile, noBottomSpace, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: ${spaces.medium}px;

  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.large8X}px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  color: ${colors.indigo[800]};

  ${({ noBottomSpace }) =>
    noBottomSpace &&
    css`
      margin-bottom: 0;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
        font-size: ${fontSizes.large2X}px;
        line-height: unset;
      `}
  }
`;

const Title = ({ desktop, mobile, noBottomSpace, children }) => (
  <StyledTitle noBottomSpace={noBottomSpace} desktop={desktop} mobile={mobile}>
    {children}
  </StyledTitle>
);

Title.propTypes = {
  noBottomSpace: PropTypes.bool,
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Title;
