import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

import Icon from '_components/atomic/Icon';

const { colors, devices, fontSizes, spaces } = theme;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaces.regular}px;
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledAction = styled(forwardRef(({ hoverTextColor, hoverIconColor, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: flex-end;

  cursor: pointer;

  ${({ hoverTextColor }) =>
    hoverTextColor &&
    css`
      &:hover {
        div {
          color: ${hoverTextColor};
        }
      }
    `}

  ${({ hoverIconColor }) =>
    hoverIconColor &&
    css`
      &:hover {
        svg {
          path {
            fill: ${hoverIconColor};
          }
        }
      }
    `}
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledActionText = styled(forwardRef(({ color, ...props }, ref) => <div ref={ref} {...props} />))`
  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.medium}px;
  font-weight: 500;
  color: ${({ color }) => color};

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledIconContainer = styled(forwardRef(({ desktop, mobile, ...props }, ref) => (<div ref={ref} {...props} />)))`
  display: flex;
  align-items: center;

  margin-left: ${spaces.small - 2}px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `}
  }
`;

const Title = ({ action, children }) => (
  <StyledTitle>
    <StyledBody>{children}</StyledBody>
    {action && (
      <StyledAction
        hoverTextColor={action?.hoverTextColor}
        hoverIconColor={action?.hoverIconColor}
        onClick={action?.onClick}
      >
        <StyledActionText color={action?.textColor || colors.indigo[500]}>
          {action?.text}
        </StyledActionText>
        {action?.iconAlias && (
          <>
            <StyledIconContainer desktop>
              <Icon
                alias={action?.iconAlias}
                color={action?.iconColor || colors.indigo[500]}
              />
            </StyledIconContainer>
            <StyledIconContainer mobile>
              <Icon
                alias={action?.iconAlias}
                color={action?.iconColor || colors.indigo[500]}
                height={20}
                width={20}
              />
            </StyledIconContainer>
          </>
        )}
      </StyledAction>
    )}
  </StyledTitle>
);

Title.propTypes = {
  action: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    hoverTextColor: PropTypes.string,
    iconAlias: PropTypes.string,
    iconColor: PropTypes.string,
    hoverIconColor: PropTypes.string,
    onClick: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Title;
