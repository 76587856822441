/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const { colors, devices, fontSizes, spaces } = theme;

const StyledText = styled(
  forwardRef(
    (
      {
        desktop,
        mobile,
        bold,
        boldest,
        color,
        centered,
        underline,
        right,
        size,
        noBottomSpace,
        ...props
      },
      ref
    ) => <div ref={ref} {...props} />
  )
)`
  width: 100%;
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.regular}px;
  font-weight: 400;
  color: ${({ color }) => color};

  span {
    color: ${colors.orange[600]};
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `}

  ${({ boldest }) =>
    boldest &&
    css`
      font-weight: 700;
    `}

  ${({ noBottomSpace }) =>
    noBottomSpace &&
    css`
      margin-bottom: 0;
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ right }) =>
    right &&
    css`
      text-align: end;
    `}

  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}

    ${({ mobile, size }) =>
      mobile &&
      size &&
      css`
        font-size: ${size}px;
      `}
  }
`;

const StyledH1 = styled(
  forwardRef(
    (
      {
        desktop,
        mobile,
        bold,
        boldest,
        color,
        centered,
        underline,
        right,
        size,
        noBottomSpace,
        ...props
      },
      ref
    ) => <h1 ref={ref} {...props} />
  )
)`
  width: 100%;
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.regular}px;
  font-weight: 400;
  color: ${({ color }) => color};

  span {
    color: ${colors.orange[600]};
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `}

  ${({ boldest }) =>
    boldest &&
    css`
      font-weight: 700;
    `}

  ${({ noBottomSpace }) =>
    noBottomSpace &&
    css`
      margin-bottom: 0;
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ right }) =>
    right &&
    css`
      text-align: end;
    `}

  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}

    ${({ mobile, size }) =>
      mobile &&
      size &&
      css`
        font-size: ${size}px;
      `}
  }
`;

const StyledH2 = styled(
  forwardRef(
    (
      {
        desktop,
        mobile,
        bold,
        boldest,
        color,
        centered,
        underline,
        right,
        size,
        noBottomSpace,
        ...props
      },
      ref
    ) => <h2 ref={ref} {...props} />
  )
)`
  width: 100%;
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.regular}px;
  font-weight: 400;
  color: ${({ color }) => color};

  span {
    color: ${colors.orange[600]};
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `}

  ${({ boldest }) =>
    boldest &&
    css`
      font-weight: 700;
    `}

  ${({ noBottomSpace }) =>
    noBottomSpace &&
    css`
      margin-bottom: 0;
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ right }) =>
    right &&
    css`
      text-align: end;
    `}

  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}

    ${({ mobile, size }) =>
      mobile &&
      size &&
      css`
        font-size: ${size}px;
      `}
  }
`;

const styledWrappers = {
  h1: StyledH1,
  h2: StyledH2,
};

const Text = ({
  children,
  as,
  desktop,
  mobile,
  bold,
  boldest,
  color,
  centered,
  underline,
  right,
  size,
  required,
  noBottomSpace,
}) => {
  const StyledWrapper = styledWrappers[as] || StyledText;
  return (
    <StyledWrapper
      desktop={desktop}
      mobile={mobile}
      bold={bold}
      boldest={boldest}
      color={color || colors.gray[800]}
      centered={centered}
      underline={underline}
      right={right}
      size={size}
      noBottomSpace={noBottomSpace}
    >
      {children}
      {required && <span>&nbsp;*</span>}
    </StyledWrapper>
  );
};

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  as: PropTypes.oneOf(['h1', 'h2']),
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  bold: PropTypes.bool,
  boldest: PropTypes.bool,
  color: PropTypes.string,
  centered: PropTypes.bool,
  underline: PropTypes.bool,
  right: PropTypes.bool,
  size: PropTypes.number,
  required: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
};

export default Text;
