import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

import Body from './Body';
import Line from './Line';
import Text from './Text';
import Title from './Title';

const { colors, devices, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCard = styled(forwardRef(({ desktop, mobile, fullHeight, fullWidth, radius, noBorder, bottomSpace, topSpace, noPadding, noPaddingMobile, ...props }, ref) => <div ref={ref} {...props} />))`
  padding: ${spaces.medium}px;
  background: ${colors.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 20px;

  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius}px;
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  ${({ bottomSpace }) =>
    bottomSpace &&
    css`
      margin-bottom: ${spaces.medium}px;
    `}

  ${({ topSpace }) =>
    topSpace &&
    css`
      margin-top: ${spaces.medium}px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    padding: ${spaces.regular}px;

    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0;
      `}

    ${({ noPaddingMobile }) =>
      noPaddingMobile &&
      css`
        padding: 0;
      `}

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

// eslint-disable-next-line react/display-name
const Card = forwardRef(
  (
    {
      mobile,
      desktop,
      fullHeight,
      fullWidth,
      noBorder,
      bottomSpace,
      topSpace,
      noPadding,
      radius,
      children,
      ...restProps
    },
    ref
  ) => (
    <StyledCard
      ref={ref}
      mobile={mobile}
      desktop={desktop}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      noBorder={noBorder}
      bottomSpace={bottomSpace}
      topSpace={topSpace}
      noPadding={noPadding}
      radius={radius}
      {...restProps}
    >
      {children}
    </StyledCard>
  )
);

Card.Line = Line;
Card.Body = Body;
Card.Text = Text;
Card.Title = Title;

Card.propTypes = {
  mobile: PropTypes.bool,
  desktop: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  bottomSpace: PropTypes.bool,
  topSpace: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  radius: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Card;
