import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const { colors, devices, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledBody = styled(forwardRef(({ desktop, mobile, bottomSpace, centered, color, fullHeight, padding, noPadding, radius, ...props }, ref) => <div ref={ref} {...props} />))`
  padding: ${spaces.medium}px;
  border-radius: 16px;
  background-color: ${colors.gray[50]};

  ${({ bottomSpace }) =>
    bottomSpace &&
    css`
      margin-bottom: ${spaces.medium}px;
    `}

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding}px;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius}px;
    `}

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    height: auto;
    width: 100%;
    padding: ${spaces.regular}px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

const Body = ({
  desktop,
  mobile,
  bottomSpace,
  centered,
  color,
  fullHeight,
  padding,
  noPadding,
  radius,
  children,
  ...restProps
}) => (
  <StyledBody
    desktop={desktop}
    mobile={mobile}
    bottomSpace={bottomSpace}
    centered={centered}
    color={color}
    fullHeight={fullHeight}
    padding={padding}
    noPadding={noPadding}
    radius={radius}
    {...restProps}
  >
    {children}
  </StyledBody>
);

Body.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  bottomSpace: PropTypes.bool,
  centered: PropTypes.bool,
  color: PropTypes.string,
  fullHeight: PropTypes.bool,
  padding: PropTypes.number,
  noPadding: PropTypes.bool,
  radius: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Body;
